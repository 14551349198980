.COMPONENTS-profile {
  color: white;
  text-align: center;
  font-size: 2vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vh;
}

.COMPONENTS-profile .pic {
  width: 20vh;
  height: 20vh;
  border-radius: 50%;
  margin-bottom: 2vh;
}

.COMPONENTS-profile .icon {
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
}
