.COMPONENTS-logo {
  width: 10vh;
  padding: none;
  margin: none;
  height: auto;
  border-radius: 1vmin;
  transform: scale(1.2);
}

.COMPONENTS-logo:hover {
  transform: scale(1.4);
  transition: transform 0.2s ease-in-out;
}
