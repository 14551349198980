.COMPONENTS-navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #000000ad;
  width: 100vw;
  height: 10vh;
  padding: 2vmin 8vmin;
  gap: 8vmin;
  box-sizing: border-box;
  font-family: "Workbench", sans-serif;
  z-index: 1300;
  backdrop-filter: blur(12px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: none;
}
