.PAGES-about {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
  height: 100vh;
}

.PAGES-about Title {
  animation: fadeIn 1s ease-out;
}

.PAGES-about .team-profiles {
  display: flex;
  justify-content: center;
  gap: 10vmin;
  margin-top: 2vh;
  animation: fadeIn 1.5s ease-out;
}


.PAGES-about p {
  animation: fadeIn 1.5s ease-out;
}
