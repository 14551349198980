.PAGES-reference {
    margin-top: 16vh;
    display: flex;
    flex-direction: column;
    gap: 5vh;
    align-items: center;
    height: 100vh;
}

  
  