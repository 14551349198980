.COMPONENTS-clickable-text {
  font-size: 3.4vmin;
  text-decoration: none;
  font-weight: 800;
}

.COMPONENTS-clickable-text:hover {
  transform: scale(1.08);
  transition: transform 0.2s ease-in-out;
}
