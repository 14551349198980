.PAGES-home {
  margin-top: 16vh;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
  color: white;
}

.PAGES-home-card {
  animation: fadeIn 1s ease-out;
}

.PAGES-home-big-demo {
  padding: left 2vmin;
}

.PAGES-home-demo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16;
  gap: 8;
  width: 25vw;
  min-height: fit-content;
}

b {
  color: #f5f5f5;
  font-weight: 800;
}

a {
  color: #51b015;
  text-decoration: none;
  font-weight: 800;
}

a:hover {
  color: #407c1a;
}
