.App {
  text-align: center;
  color: white;
}

body {
  background-image: linear-gradient(to right bottom, #141414, #000000);
  background-attachment: fixed;
  height: 100vh;
}

h1 {
  color: white;
  font-size: 5vmin;
  margin: 0;
}

p {
  text-align: left;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 500;
  max-width: 70vw;
}
